import i18next from 'i18next';

i18next.addResources('es', 'Donate', {
  title: 'Donar',
  // Change when the cliente pass the text
  aboutUs: `Blaze is an initiative of The Grace Institute (TGI). TGI is an international ministry that trains, prepares, equips, and resources church leaders for Christian ministry. The vast majority of the leaders that are being reached by TGI do not have the financial resources for Biblical education. Therefore, just like the Blaze platform, we provide Biblical training for free.\n
    The Grace Institute is a nonprofit organization that depends on the generosity of churches and individuals like you to help reach many church leaders around the world.\n
    Would you consider donating to this global effort?
  `,
  subtitle: '3 diferentes formas de apoyar a Teleios',
  donateButton: 'Donar'
});

i18next.addResources('en', 'Donate', {
  title: 'Donate',
  aboutUs: `Blaze is an initiative of The Grace Institute (TGI). TGI is an international ministry that trains, prepares, equips, and resources church leaders for Christian ministry. The vast majority of the leaders that are being reached by TGI do not have the financial resources for Biblical education. Therefore, just like the Blaze platform, we provide Biblical training for free.\n
    The Grace Institute is a nonprofit organization that depends on the generosity of churches and individuals like you to help reach many church leaders around the world.\n
    Would you consider donating to this global effort?
  `,
  subtitle: '3 different ways to support Blaze',
  donateButton: 'Donate'
});
