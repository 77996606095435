import i18next from 'i18next';

i18next.addResources('es', 'AppFooter', {
  copyright: '© 2021 Teleios All rights reserved.',
  contact: '',
  privacyPolicy: 'Política de Privacidad',
  altFooterLogo: 'Teleios logo',
  madeWith: 'Made with ❤️ by Antorcha Digital'
});

i18next.addResources('en-US', 'AppFooter', {
  copyright: '© 2021 Blaze All rights reserved.',
  contact: 'An initiative of The Grace Institute',
  privacyPolicy: 'Privacy Policy',
  altFooterLogo: 'Blaze logo',
  madeWith: 'Made with ❤️ by Antorcha Digital'
});
