import i18next from 'i18next';

i18next.addResources('es', 'Routes', {
  home: '/',
  categories: '/categorias',
  category: '/categoria/:id',
  course: '/curso/:id',
  login: '/login',
  recoverPassword: '/recuperar_contraseña',
  registration: '/signup',
  profile: '/perfil',
  activeCourse: '/mis_cursos/:id',
  userCourse: '/mis_cursos',
  search: '/buscar',
  privacy: '/privacidad',
  donate: '/donar',
  contact: '/contacto'
});

i18next.addResources('en-US', 'Routes', {
  home: '/',
  categories: '/categories',
  category: '/category/:id',
  course: '/course/:id',
  login: '/login',
  recoverPassword: '/recover_password',
  registration: '/signup',
  profile: '/profile',
  activeCourse: '/my_courses/:id',
  userCourse: '/my_courses',
  search: '/search',
  privacy: '/privacy',
  donate: '/donate',
  contact: '/contact'
});
