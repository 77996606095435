import { lazy } from 'react';

import { Nullable } from '~utils/types';
import { User } from '~interfaces/authInterfaces';
import { REGISTER_ENABLED } from '~constants/theme';

import PATHS from './paths';

const Home = lazy(() => import('../../screens/Dashboard'));
const Login = lazy(() => import('../../screens/Login'));
const RecoverPassword = lazy(() => import('../../screens/RecoverPassword'));
const Registration = lazy(() => import('../../screens/Registration'));

const MAIN_PRIVATE_PATH = PATHS.home;

/* When adding routes, add them ABOVE the Home route
 * or it will redirect you to incorrect screens */
export const ROUTES = [
  {
    exact: false,
    path: PATHS.registration,
    component: Registration,
    isVisible: REGISTER_ENABLED
  },
  {
    exact: false,
    path: PATHS.recoverPassword,
    component: RecoverPassword,
    isVisible: true
  },
  {
    exact: false,
    path: PATHS.login,
    component: Login,
    isVisible: true,
    redirectTo: (user: Nullable<User>) => (user ? MAIN_PRIVATE_PATH : undefined)
  },
  {
    exact: false,
    path: PATHS.home,
    isVisible: true,
    component: Home
  }
];
