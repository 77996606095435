import { Serializer, CamelcaseSerializer } from 'cerealizr';
import toCamelCase from 'lodash.camelcase';
import capitalize from 'lodash.capitalize';

const toUpperCamelCase = (str: string) => capitalize(toCamelCase(str));

export const upperCamelcaseSerializer = new Serializer({
  mapAllValues: true,
  defaultTransform: (key: string, value: any) => ({
    [toUpperCamelCase(key)]: value
  })
});

export const camelcaseSerializer = new CamelcaseSerializer();
